import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import styled from "styled-components";
import Fade from "@material-ui/core/Fade";

import { colors } from "../utils/constants";
import { LoginForm } from "../forms";

const Background = styled.div`
	background-color: ${colors.light} !important;
	height: ${window.innerHeight}px;
	width: 100%;
`;

const useStyles = makeStyles((theme) => ({
	paper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	title: {
		color: theme.palette.common.black,
		fontWeight: 400,
		fontSize: 30,
		letterSpacing: 0.92,
		marginTop: 120,
		marginBottom: 0,
		position: "relative",
	},
	user: {
		color: theme.palette.common.black,
		fontWeight: 400,
		fontSize: 30,
		height: 86,
		letterSpacing: 0.92,
		position: "relative",
	},
}));

export default function Login({ history }) {
	const classes = useStyles();
	return (
		<Background>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>
					<Fade in={true} timeout={1700}>
						<Typography component="h1" variant="h5" className={classes.title}>
							Access Control
						</Typography>
					</Fade>
					<Fade in={true} timeout={4000}>
						<Typography component="h1" variant="h5" className={classes.user}>
							Login
						</Typography>
					</Fade>
					<LoginForm history={history} />
				</div>
			</Container>
		</Background>
	);
}

import React, { useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { Link, MuiThemeProvider } from "@material-ui/core";
import axios from "axios";

import LoginFormSchema from "./LoginFormSchema";
import { colors } from "../utils/constants";
import CustomButton from "../components/CustomButton";
import DialogModal from "../components/DialogModal";

const theme = createMuiTheme({
	palette: {
		primary: {
			main: colors.primary,
		},
	},
});

const Container = styled.div`
	width: 100%;
	max-width: 300px;
	display: flex;
	flex-direction: column;
`;

const Label = styled.label`
	font-size: 12px;
	display: flex;
	margin-top: 20px;
	color: ${colors.darkGray};
`;

const Error = styled.label`
	font-size: 12px;
	color: red;
	display: ${(e) => (e ? "block" : "none")};
	margin-top: 10px;
`;

const InputText = styled.input`
	display: flex;
	height: 44px;
	width: 100%;
	padding: 10px;
	margin: 2px 0;
	max-width: 880px;
	box-shadow: 0px 2px 13px 3px rgba(0, 0, 0, 0.11);
	border-radius: 4px;
	border: none;
	font-size: 16px;
	letter-spacing: 1.1px;
	align-items: center;
	background-color: ${colors.white};
	-webkit-appearance: none;
	:focus {
		outline-color: ${colors.primary};
	}
`;

const ButtonContainer = styled.div`
	display: block;
	margin-top: 40px;
	text-align: center;
`;

const useStyles = makeStyles(() => ({
	link: {
		color: colors.primary,
	},
}));

const LoginForm = ({ history }) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [error, setError] = useState(false);
	const classes = useStyles();

	const handleSubmit = async (values) => {
		try {
			const { data } = await axios.post("/api/auth/login", values);
			localStorage.setItem("token", data.token);
			history.push("/");
		} catch (e) {
			setError("Unable to verify identity");
		}
	};

	const formik = useFormik({
		initialValues: { email: "", password: "" },
		validationSchema: LoginFormSchema,
		onSubmit: handleSubmit,
	});

	const handleForgotPassword = () => {
		setModalOpen(true);
	};

	return (
		<Container>
			<form onSubmit={formik.handleSubmit}>
				<Label>Email address</Label>
				<InputText
					id="email"
					name="email"
					type="email"
					onChange={formik.handleChange}
					onBlur={() => formik.setFieldTouched("email")}
					value={formik.values.email}
				/>
				<Error error={formik.touched.email && formik.errors.email}>{formik.touched.email && formik.errors.email}</Error>
				<Label>Password</Label>
				<InputText
					id="password"
					name="password"
					type="password"
					autocomplete="new-password"
					onChange={formik.handleChange}
					onBlur={() => formik.setFieldTouched("password")}
					value={formik.values.password}
				/>
				<Error error={formik.touched.password && formik.errors.password}>
					{formik.touched.password && formik.errors.password}
				</Error>
				<Link href="#" onClick={handleForgotPassword} className={classes.link}>
					Forgot password?
				</Link>
				<ButtonContainer>
					<CustomButton type="submit" text="Login" loading={formik.isSubmitting} />
					<Error error={error}>{error}</Error>
				</ButtonContainer>
			</form>
			<MuiThemeProvider theme={theme}>
				<DialogModal
					title="Forgot password?"
					text={"Please contant the system administrator to help you reset your password."}
					onAknowledge={() => setModalOpen(false)}
					modalOpen={modalOpen}
				/>
			</MuiThemeProvider>
		</Container>
	);
};

export default LoginForm;

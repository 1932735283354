import React, { useState, useEffect } from "react";
import axios from "axios";
import Token from "../utils/Token";

const AuthContext = React.createContext();

const AuthProvider = ({ history, children }) => {
	const [userData, setUserData] = useState(null);

	const onLogout = () => {
		setUserData(null);
		localStorage.clear();
		history.push("/login");
	};

	const reloadUserData = () => {
		const token = localStorage.getItem("token");
		if (token) {
			const payload = JSON.parse(Token.user(token));
			const levels = { user: 1, admin: 2, root: 3 };
			const accessLevel = levels[payload.type] || 1;
			setUserData({ ...payload, accessLevel });
		}
	};

	useEffect(() => {
		const verifyToken = async () => {
			const token = localStorage.getItem("token");
			try {
				await axios.post("/api/token", { token });
				const payload = JSON.parse(Token.user(token));
				const levels = { user: 1, admin: 2, root: 3 };
				const accessLevel = levels[payload.type] || 1;
				setUserData({ ...payload, accessLevel });
			} catch (e) {
				setTimeout(() => {
					history.push("/login");
				}, 100);
			}
		};

		if (window.location.pathname !== "/login") {
			verifyToken();
		}
	}, [history]);

	return <AuthContext.Provider value={{ userData, onLogout, reloadUserData }}>{children}</AuthContext.Provider>;
};

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };
export default AuthContext;

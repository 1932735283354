import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

import { colors } from "../utils/constants";

const Container = styled.button`
	display: flex;
	background: ${({ color }) => color || colors.primary};
	color: ${colors.white};
	width: 100%;
	border: none;
	height: 45px;
	cursor: pointer;
	outline: none;
	padding: 5px 20px;
	border-radius: 4px;
	margin: auto;
	transition: 0.2s;
	justify-content: center;
	align-items: center;
	:hover {
		background: ${({ hoverColor }) => hoverColor || colors.primaryHover};
	}
`;

export default function CustomButton({
	text,
	onClick,
	loading = false,
	type = "button",
	color,
	hoverColor,
	icon,
	style,
}) {
	return (
		<Container type={type} onClick={onClick} color={color} hoverColor={hoverColor} style={style}>
			{loading && <CircularProgress color={colors.white} size={25} />}
			{!loading && (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						textAlign: "center",
					}}
				>
					{icon && (
						<p style={{ marginRight: 6, textAlign: "center", paddingTop: 3, transform: "rotate(180deg)" }}>{icon}</p>
					)}
					<div style={{ margin: "auto", fontWeight: 600, fontSize: 14 }}>{text}</div>
				</div>
			)}
		</Container>
	);
}

import themes from "./themes";
const whiteLabel = require("../../package.json").whiteLabel;

export const colors = {
	yellow: "#fbcb03",
	green: "#03fb3a",
	primary: "#6b00ff",
	primaryHover: "#5402c5",
	primaryDark: "#4b4b67",
	light: "#f6f6f6",
	lightGray: "#e2e2e2",
	gray: "#c5c5c5",
	darkGray: "#595858",
	darkerGray: "#404040",
	dark: "#3b3b3b",
	white: "#fff",
	danger: "#eb092f",
	...themes[whiteLabel],
};

export const messages = {
	welcome: "Welcome to Montrose Crescent Car Park",
	initial: "Please enter your full vehicle registration to enter the car park",
	support: "If you require assistance, please use the intercom or call our help line number 0114 2509 753",
	authSuccess: "Access granted",
	authError: "Sorry we couldn't find your registration in the system. Please try again",
	authPending: "Verifying, please wait...",
	commentPlaceholder: "Please leave a comment stating the circumstances of the manual approval",
};

export default Object.freeze({
	colors,
	messages,
});

import React, { useContext, useState } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Paper from "@material-ui/core/Paper";
import { IconButton, MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";

import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";

import AuthContext from "../context/AuthContext";
import ConfirmModal from "./ConfirmModal";
import { colors } from "../utils/constants";
import { isMobile } from "react-device-detect";

const theme = createMuiTheme({
	palette: {
		primary: {
			main: colors.danger,
		},
		secondary: {
			main: colors.primary,
		},
	},
});

const cellStyle = { fontSize: isMobile ? 10 : 14 };

const UsersTable = ({ users, history }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [usersList, setUsersList] = useState(users);
	const [modalOpen, setModalOpen] = useState(false);
	const [userToDelete, setUserToDelete] = useState(null);
	const { userData } = useContext(AuthContext);

	const handleDeleteUser = (email) => {
		setUserToDelete(email);
		setModalOpen(true);
	};

	const handleConfirmDeleteUser = async (email) => {
		try {
			await axios.delete(`/api/users?email=${email}`);
			const filteredUsers = usersList.filter(({ email }) => userToDelete !== email);
			setUsersList(filteredUsers);
			enqueueSnackbar(`${email} has been deleted.`);
		} catch (e) {
			enqueueSnackbar(`Failed to delete ${email}`, { variant: "error" });
		}
		setModalOpen(false);
	};

	return (
		<TableContainer component={Paper}>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow>
						<TableCell align="left" key="Email" style={cellStyle}>
							Email
						</TableCell>
						<TableCell align="left" key="Type" style={cellStyle}>
							Type
						</TableCell>
						<TableCell align="right" key="Actions" style={{ paddingRight: 38, ...cellStyle }}>
							Actions
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{usersList &&
						usersList.map(({ email, type }) => {
							if (userData && userData.accessLevel < 3 && type === "root") return null;
							return (
								<TableRow key={email}>
									<TableCell align="left" key="Email" style={cellStyle}>
										{email}
									</TableCell>
									<TableCell align="left" key="Type" style={cellStyle}>
										{type}
									</TableCell>
									<TableCell align="right" key="Actions" style={{ display: "flex", ...cellStyle }}>
										<IconButton key="delete" onClick={() => handleDeleteUser(email)}>
											<DeleteIcon style={{ fontSize: isMobile ? 20 : 22 }} />
										</IconButton>
										<IconButton key="edit" onClick={() => history.push("/management/users/edit", { email, type })}>
											<EditIcon style={{ fontSize: isMobile ? 20 : 22 }} />
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
			<MuiThemeProvider theme={theme}>
				<ConfirmModal
					title="Are you sure?"
					text={`${userToDelete} will be permanently deleted and will no longer have access to the portal. Please confirm whether you intend to proceed.`}
					onConfirm={() => handleConfirmDeleteUser(userToDelete)}
					onDisagree={() => setModalOpen(false)}
					modalOpen={modalOpen}
				/>
			</MuiThemeProvider>
		</TableContainer>
	);
};

export default withRouter(UsersTable);

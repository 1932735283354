import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import MainProvider from "./context/MainProvider";

import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";
import Locations from "./screens/Locations";
import Management from "./screens/Management";
import NewUser from "./screens/NewUser";
import EditUser from "./screens/EditUser";

import ProtectedRoute from "./containers/ProtectedRoute";

import "./assets/css/index.css";

const history = createBrowserHistory();

const App = () => {
	return (
		<MainProvider history={history}>
			<BrowserRouter>
				<Switch>
					<Route path="/login" component={Login} />
					<ProtectedRoute path="/" history={history} exact component={Dashboard} />
					<ProtectedRoute path="/locations" history={history} exact component={Locations} />
					<ProtectedRoute path="/management" history={history} exact component={Management} requiredLevel={2} />
					<ProtectedRoute path="/management/users/new" history={history} exact component={NewUser} requiredLevel={2} />
					<ProtectedRoute
						path="/management/users/edit"
						history={history}
						exact
						component={EditUser}
						requiredLevel={2}
					/>
				</Switch>
			</BrowserRouter>
		</MainProvider>
	);
};

export default App;

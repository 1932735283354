import React from "react";
import Page from "../containers/Page";
import Shell from "../containers/Shell";
import NewUserForm from "../forms/NewUserForm";

const NewUser = ({ history }) => {
	return (
		<Page history={history}>
			<Shell title="Create new user" subtitle="Setup a new user account">
				<NewUserForm />
			</Shell>
		</Page>
	);
};

export default NewUser;

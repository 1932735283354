import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { colors } from '../utils/constants';
import TopBar from '../components/TopBar';
import AuthContext from '../context/AuthContext';
import Token from '../utils/Token';

const Container = styled.div`
	background: ${colors.primary};
	width: 100%;
	height: 100%;
`;

export default function Page({ history, children }) {
	const { reloadUserData, userData } = useContext(AuthContext);

	useEffect(() => {
		reloadUserData();
		// eslint-disable-next-line
	}, [window.location.pathname]);

	if (userData && userData.accessLevel < 2 && window.location.pathname.includes('/management')) {
		history.push('/');
	}

	if (!Token.exists()) {
		history.push('/login');
		return null;
	}

	return (
		<Container>
			<TopBar history={history} />
			{children}
		</Container>
	);
}

import React, { useEffect, useState } from "react";
import Axios from "axios";

import Page from "../containers/Page";
import UsersTable from "../components/UsersTable";
import Shell from "../containers/Shell";
import CustomButton from "../components/CustomButton";

const Management = ({ history }) => {
	const [users, setUsers] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		getUsers();
	}, []);

	const getUsers = async () => {
		setLoading(true);
		setError(false);
		try {
			const { data } = await Axios.get("/api/users");
			setUsers(data);
		} catch (e) {
			setError(true);
		}
		setLoading(false);
	};

	const EndSlot = () => {
		return <CustomButton text="Create new user" onClick={() => history.push("/management/users/new")} />;
	};

	return (
		<Page history={history}>
			<Shell
				title="Management"
				subtitle="Manage users and their permissions"
				loading={loading}
				error={error}
				endSlot={<EndSlot />}
			>
				{users && <UsersTable users={users} />}
			</Shell>
		</Page>
	);
};

export default Management;

import React from "react";

import Page from "../containers/Page";
import PollIcon from "@material-ui/icons/Poll";

import Shell from "../containers/Shell";
import EmptyState from "../components/EmptyState";
import { colors } from "../utils/constants";

const Locations = ({ history }) => {
	return (
		<Page history={history}>
			<Shell title="Locations" subtitle="Manage locations and car parks">
				<EmptyState
					title="Coming Soon"
					subtitle="Soon you'll be able to view and manage your locations"
					icon={<PollIcon style={{ height: 65, width: 65, fill: colors.dark }} />}
				/>
			</Shell>
		</Page>
	);
};

export default Locations;

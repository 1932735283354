import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Paper from "@material-ui/core/Paper";
import ErrorIcon from "@material-ui/icons/HighlightOff";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import { CircularProgress } from "@material-ui/core";
import Axios from "axios";

import { useSnackbar } from "notistack";

import DateUtils from "../utils/DateUtils";
import { colors } from "../utils/constants";
import CustomButton from "./CustomButton";

const useRowStyles = makeStyles(() => ({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},
}));

function Row({ initialRow, rowKey }) {
	const { enqueueSnackbar } = useSnackbar();
	const [row, setRow] = useState(initialRow);
	const [isOpening, setIsOpening] = useState(false);
	const [isReloading, setIsReloading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const classes = useRowStyles();

	const cells = Object.keys(row);

	const isDown = row.status === "DOWN";

	const handleOpen = async () => {
		if (isOpening) return;
		setHasError(false);
		setIsOpening(true);
		const { port, deviceIP } = row;
		try {
			await Axios.post(`/api/override/relay?deviceAddr=${deviceIP}:${port}`);
			setIsSuccess(true);
			enqueueSnackbar(`Opened relay on port ${port}.`);
		} catch (e) {
			setHasError(true);
			enqueueSnackbar(`Failed to open relay on port ${port}.`);
		}
		setIsOpening(false);
		setTimeout(() => {
			setIsSuccess(false);
		}, 5000);
	};

	const handleReload = async () => {
		setIsReloading(true);
		setHasError(false);
		try {
			const { port, deviceIP } = row;
			const { data } = await Axios.post(`/api/health/single?deviceAddr=${deviceIP}:${port}`);
			setRow({
				...initialRow,
				status: (data && data.message) || "DOWN",
				uptime: (data && DateUtils.convertToSince(data.uptime)) || "N/A",
			});
		} catch (e) {
			setHasError(true);
		}
		setIsReloading(false);
	};

	const OpenButton = ({ child }) => {
		return (
			<div style={{ maxWidth: 100, minWidth: 70, margin: "auto" }}>
				<CustomButton key={rowKey} text={child} onClick={handleOpen} />
			</div>
		);
	};

	const ReloadButton = ({ child }) => {
		return (
			<div style={{ maxWidth: 100, minWidth: 70, margin: "auto" }}>
				<CustomButton key={rowKey} text={child} onClick={handleReload} />
			</div>
		);
	};

	return (
		<TableRow className={classes.root} style={{ background: isDown ? colors.danger : "white" }}>
			<TableCell>
				{!isDown && (
					<>
						{!isSuccess &&
							!hasError &&
							(isOpening ? (
								<OpenButton child={<CircularProgress color={colors.white} size={24} />} />
							) : (
								<OpenButton child={<span style={{ fontSize: isMobile ? 10 : 14 }}>OPEN</span>} />
							))}
						{!isSuccess && hasError && <OpenButton child={<ErrorIcon style={{ color: colors.white }} />} />}
						{isSuccess && <OpenButton child={<SuccessIcon style={{ color: colors.white }} />} />}
					</>
				)}
				{isDown && (
					<>
						{!hasError &&
							(isReloading ? (
								<ReloadButton child={<CircularProgress color={colors.white} size={24} />} />
							) : (
								<ReloadButton child="RELOAD" />
							))}
						{hasError && <ReloadButton child={<ErrorIcon style={{ color: colors.danger }} />} />}
					</>
				)}
			</TableCell>
			{cells &&
				cells.map((cell, idx) => {
					if (cell === "deviceIP") return null;
					return (
						<TableCell align="center" key={idx}>
							{<div style={{ color: colors.dark, fontSize: isMobile ? 10 : 14 }}>{row[cell]}</div>}
						</TableCell>
					);
				})}
		</TableRow>
	);
}

export default function HealthTable({ headers = [], rows = [] }) {
	return (
		<TableContainer component={Paper}>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow>
						{headers.map((header, idx) => {
							return (
								<TableCell align="center" key={idx}>
									{header}
								</TableCell>
							);
						})}
					</TableRow>
				</TableHead>
				<TableBody>{rows && rows.map((row, idx) => <Row key={idx} rowKey={idx} initialRow={row} />)}</TableBody>
			</Table>
		</TableContainer>
	);
}

import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';

import { colors } from '../utils/constants';
import CarParkContext from '../context/CarParkContext';
import HealthTable from '../components/HealthTable';
import Page from '../containers/Page';
import Shell from '../containers/Shell';

const LocationTitle = styled.h3`
	color: ${colors.lightDark};
`;

const Dashboard = ({ history }) => {
	const { healthMetrics: health, loadingHealth: loading, getHealthMetrics } = useContext(CarParkContext);

	useEffect(() => {
		getHealthMetrics();
		// eslint-disable-next-line
	}, []);

	return (
		<Page history={history}>
			<Shell title="Dashboard" subtitle="Live system health and remote control panel" loading={loading}>
				{health &&
					health.map(({ city, location, data }) => {
						return (
							<div key={`${location}-container`}>
								<LocationTitle>
									{city} - {location}
								</LocationTitle>
								<HealthTable
									headers={['Action', 'Device', 'City', 'Door', 'Port', 'Status', 'Uptime']}
									rows={data}
								/>
								<br />
							</div>
						);
					})}
			</Shell>
		</Page>
	);
};

export default Dashboard;

import React from "react";
import Page from "../containers/Page";
import Shell from "../containers/Shell";
import EditUserForm from "../forms/EditUserForm";

const EditUser = ({ history, location }) => {
	if (!location || !location.state) {
		history.push("/management");
		return null;
	}

	const { email, type } = location.state;

	return (
		<Page history={history}>
			<Shell title="Edit user" subtitle="Edit user email and type (password is not amenable at this moment)">
				<div style={{ paddingBottom: 200 }}>
					<EditUserForm initialValues={{ email, type }} />
				</div>
			</Shell>
		</Page>
	);
};

export default EditUser;

import { object, string } from 'yup';

const schema = object().shape({
  email: string()
    .email('Must be a valid email')
    .required('Email is required'),
  password: string().required('Password is required'),
});

export default schema;

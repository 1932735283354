import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import { colors } from "../utils/constants";
import CustomButton from "../components/CustomButton";
import AuthContext from "../context/AuthContext";
import UserPicture from "../assets/images/logo.png";
import { Tabs, Tab } from "@material-ui/core";

const Avatar = styled.img`
	height: ${isMobile ? "40" : "50"}px;
	width: ${isMobile ? "40" : "50"}px;
	border-radius: 50%;
`;

const LogoContainer = styled.div`
	background: ${colors.white};
	border-radius: 50%;
`;

const Navbar = styled.nav`
	justify-content: space-between;
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: auto;
	width: inherit;
	max-width: 1140px;
	padding: 0px ${isMobile ? 16 : 0}px;
`;

const tabStyles = {
	color: colors.white,
	textTransform: "none",
	minWidth: "unset",
	fontSize: 14,
	fontWeight: 400,
	marginRight: 16,
	paddingLeft: 0,
	paddingRight: 0,
};

const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
		height: isMobile ? 130 : 110,
		background: colors.primary,
		top: 0,
		width: "100%",
		zIndex: -1,
		display: "flex",
		justifyContent: "space-around",
		justifyItems: "center",
	},
	title: {
		padding: 0,
		minWidth: isMobile ? "unset" : 140,
		fontSize: isMobile ? 20 : 28,
		color: colors.white,
		fontWeight: "bold",
		cursor: "pointer",
	},
	toolbar: { padding: 0, marginTop: 10, display: "flex", alignItems: "center" },
	indicator: { backgroundColor: colors.secondary, height: 4, opacity: 0.8 },
}));

export default function TopBar({ history }) {
	const indexes = { "/": 0, "/locations": 1, "/management": 2 };
	const { onLogout, userData } = useContext(AuthContext);

	const currentPath = window.location.pathname;
	const currentIndex =
		indexes[currentPath.replace(/[^/]/g, "").length > 1 ? "/" + currentPath.split("/")[1] : currentPath || "/"];

	const accessLevel = userData && userData.accessLevel;

	const classes = useStyles();

	const handleLogout = () => onLogout();

	return (
		<div className={classes.root}>
			<AppBar position="static">
				<Navbar>
					<Toolbar variant="dense" className={classes.toolbar}>
						<Typography variant="h4" className={classes.title} onClick={() => history.push("/")}>
							Bank Park
						</Typography>
						<LogoContainer style={{ marginLeft: 20 }}>
							<IconButton style={{ margin: -10 }}>
								<Avatar src={UserPicture} />
							</IconButton>
						</LogoContainer>
						<CustomButton
							text="Logout"
							onClick={handleLogout}
							style={{ width: 120, marginLeft: "auto", marginRight: isMobile ? 16 : 0 }}
							icon={<LogoutIcon />}
						/>
					</Toolbar>
					<Tabs
						value={currentIndex}
						indicatorColor={"secondary"}
						classes={{ indicator: classes.indicator }}
						style={{ padding: 0 }}
					>
						<Tab label="Dashboard" style={tabStyles} disableRipple onClick={() => history.push("/")} />
						<Tab label="Locations" style={tabStyles} disableRipple onClick={() => history.push("/locations")} />
						{accessLevel > 1 && (
							<Tab label="Management" style={tabStyles} disableRipple onClick={() => history.push("/management")} />
						)}
					</Tabs>
				</Navbar>
			</AppBar>
		</div>
	);
}

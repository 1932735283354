import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useFormik } from "formik";
import { FormControlLabel, Switch, withStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";

import UserSchema from "./UserSchema";
import CustomButton from "../components/CustomButton";
import { colors } from "../utils/constants";

const CustomSwitch = withStyles({
	switchBase: {
		color: colors.secondaryHover,
		"&$checked": {
			color: colors.secondary,
		},
		"&$checked + $track": {
			backgroundColor: colors.secondaryDark,
		},
	},
	checked: {},
	track: {},
})(Switch);

const Form = styled.form`
	max-width: 400px;
	padding-bottom: 20px;
`;

const Label = styled.label`
	font-size: 12px;
	display: flex;
	margin-top: 20px;
	color: ${colors.darkGray};
`;

const Error = styled.label`
	font-size: 12px;
	color: red;
	display: ${(e) => (e ? "block" : "none")};
	margin-top: 10px;
`;

const InputText = styled.input`
	display: flex;
	height: 44px;
	width: 100%;
	padding: 10px;
	margin: 2px 0;
	max-width: 880px;
	box-shadow: 0px 2px 13px 3px rgba(0, 0, 0, 0.11);
	border-radius: 4px;
	border: none;
	font-size: 16px;
	letter-spacing: 1.1px;
	align-items: center;
	background-color: ${colors.white};
	-webkit-appearance: none;
	:focus {
		outline-color: ${colors.primary};
	}
`;

const ButtonContainer = styled.div`
	display: block;
	margin-top: 40px;
	text-align: center;
`;

const EditUserForm = ({ history, initialValues }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [isAdmin, setIsAdmin] = useState(initialValues && initialValues.type === "admin");

	const handleSubmit = async (values) => {
		try {
			await axios.put("/api/users", values);
			history.push("/management");
			enqueueSnackbar("User details have been amended.");
		} catch (e) {
			enqueueSnackbar("Failed to amend user details, please try again later.", { variant: "error" });
		}
	};

	const formik = useFormik({
		initialValues: { email: initialValues.email, password: "", type: initialValues.type },
		validationSchema: UserSchema,
		onSubmit: handleSubmit,
	});

	const toggleAdmin = () => {
		setIsAdmin(!isAdmin);
		formik.setFieldValue("type", !isAdmin ? "admin" : "user");
	};

	return (
		<Form onSubmit={formik.handleSubmit}>
			<Label>Email address</Label>
			<InputText
				id="email"
				name="email"
				type="email"
				autoComplete="nope"
				onChange={formik.handleChange}
				onBlur={() => formik.setFieldTouched("email")}
				value={formik.values.email}
			/>
			<Error error={formik.touched.email && formik.errors.email}>{formik.touched.email && formik.errors.email}</Error>
			<Label>Confirm Password</Label>
			<InputText
				id="password"
				name="password"
				type="password"
				autoComplete="new-password"
				onChange={formik.handleChange}
				onBlur={() => formik.setFieldTouched("password")}
				value={formik.values.password}
			/>
			<Error error={formik.touched.password && formik.errors.password}>
				{formik.touched.password && formik.errors.password}
			</Error>
			<FormControlLabel control={<CustomSwitch checked={isAdmin} onChange={toggleAdmin} />} label="Admin" />
			<ButtonContainer>
				<CustomButton text="Edit user" loading={formik.isSubmitting} onClick={() => formik.submitForm()} />
			</ButtonContainer>
		</Form>
	);
};

export default withRouter(EditUserForm);

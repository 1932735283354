import React from "react";
import { AuthProvider } from "./AuthContext";
import { CarParkProvider } from "./CarParkContext";
import { SnackbarProvider } from "notistack";

export default function MainProvider({ history, children }) {
	return (
		<AuthProvider history={history}>
			<SnackbarProvider maxSnack={5}>
				<CarParkProvider>{children}</CarParkProvider>
			</SnackbarProvider>
		</AuthProvider>
	);
}

import React from "react";
import { Route, Redirect } from "react-router-dom";
import Token from "../utils/Token";

const ProtectedRoute = ({ component: Component, requiredLevel = 1, ...rest }) => {
	const token = localStorage.getItem("token");

	return (
		<Route
			{...rest}
			render={(props) => {
				if (token) {
					if (requiredLevel >= Token.accessLevel(token)) {
						return (
							<Redirect
								to={{
									pathname: "/",
									state: {
										from: props.location,
									},
								}}
							/>
						);
					}
					return <Component {...rest} {...props} />;
				} else {
					return (
						<Redirect
							to={{
								pathname: "/login",
								state: {
									from: props.location,
								},
							}}
						/>
					);
				}
			}}
		/>
	);
};

export default ProtectedRoute;

const exists = () => {
	const token = localStorage.getItem("token");

	return !!token;
};

const user = (token) => {
	const base64Payload = String(token).split(".")[1];
	const payload = atob(base64Payload);
	return payload;
};

const accessLevel = (token) => {
	const base64Payload = String(token).split(".")[1];
	const { type } = atob(base64Payload);
	const levels = { user: 1, admin: 2, root: 3 };
	return levels[type];
};

module.exports = {
	exists,
	user,
	accessLevel,
};

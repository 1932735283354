import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CarParkContext = React.createContext();

const CarParkProvider = ({ children }) => {
	const [cities, setCities] = useState('london,sheffield');
	const [healthMetrics, setHealthMetrics] = useState([]);
	const [healthError, setHealthError] = useState(false);
	const [loadingHealth, setLoadingHealth] = useState(false);

	const getHealthMetrics = async () => {
		setLoadingHealth(true);
		try {
			const { data } = await axios.get(`/api/health/remote?cities=${cities}`);
			setHealthMetrics(data);
			setHealthError(false);
		} catch (e) {
			setHealthError(true);
		}
		setLoadingHealth(false);
	};

	useEffect(() => {
		getHealthMetrics();
		// eslint-disable-next-line
	}, [cities]);

	return (
		<CarParkContext.Provider
			value={{
				cities,
				setCities,
				loadingHealth,
				healthMetrics,
				healthError,
				getHealthMetrics
			}}
		>
			{children}
		</CarParkContext.Provider>
	);
};

const CarParkConsumer = CarParkContext.Consumer;

export { CarParkProvider, CarParkConsumer };
export default CarParkContext;

import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { colors } from '../utils/constants';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const Container = styled.div`
	background: #eaeaea;
	height: ${window.innerHeight}px;
	padding: 16px 0;
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;
	scrollbar-width: none;
`;

const Center = styled.div`
	padding: 16px ${isMobile ? 16 : 0}px;
	max-width: 1140px;
	margin: auto;
	padding-bottom: 200px;
`;

const Heading = styled.div`
	display: flex;
	flex-direction: ${isMobile ? 'column' : 'row'};
	margin-bottom: 16px;
	justify-content: space-between;
	align-items: ${isMobile ? 'unset' : 'center'};
`;

const Title = styled.h1`
	margin: 0 0 16px 0;
`;

const Subtitle = styled.h4`
	margin: 0 0 16px 0;
	font-weight: 400;
	font-size: 14px;
	color: ${colors.darkGray};
`;

const LeftGroup = styled.div`
	display: flex;
	flex-direction: column;
`;

const RightGroup = styled.div`
	display: flex;
	flex-direction: row;
	float: right;
`;

const EndSlot = styled.div``;

const Loader = styled.div`
	width: 100%;
`;

const useStyles = makeStyles(() => ({
	body: {
		background: colors.white
	},
	progress: {
		width: '100%',
		borderRadius: 30,
		height: isMobile ? 5 : 7
	}
}));

const theme = createMuiTheme({
	palette: {
		secondary: {
			main: colors.secondary
		}
	}
});

export default function Shell({ title, subtitle, endSlot, loading, error, children }) {
	const classes = useStyles();

	return (
		<Container>
			<Center>
				<Heading>
					<LeftGroup>
						<Title>{title}</Title>
						<Subtitle>{subtitle}</Subtitle>
					</LeftGroup>
					<RightGroup>
						<EndSlot>{endSlot}</EndSlot>
					</RightGroup>
				</Heading>
				{loading && (
					<Loader>
						<MuiThemeProvider theme={theme}>
							<LinearProgress
								style={{ background: colors.secondaryDark }}
								color="secondary"
								className={classes.progress}
							/>
						</MuiThemeProvider>
					</Loader>
				)}
				{!loading && !error && children}
			</Center>
		</Container>
	);
}
